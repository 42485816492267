import { createWebHashHistory, createRouter } from "vue-router";

// import Market from '/src/templates/Market';
import Partner from "/src/templates/Partner"

// import Catalog from "@/Catalog"

const routes = [
  // {
  //   path: '/home',
  //   name: 'home',
  //   component: Market,
  //   children: [
  //     {
  //       path: ':city?/',
  //       name: 'market',
  //       component: Catalog
  //     }
  //   ]
  // },
  {
    path: "/",
    name: "partner",
    component: Partner
  }
]

export default createRouter({
  history: createWebHashHistory(),
  routes
})

