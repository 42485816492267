import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'

import MButton from "@/UI/Button"
import MChip from "@/UI/Chip"
import MInput from "@/UI/Input"
import MTabs from "@/UI/Tabs"

import router from "ROUTER/"

import Settings from "/src/assets/settings-exapmle.json"

(async () => {

  const app = createApp(App)
  app.provide("Settings", Settings)

  app.provide("PartnerId", location.host)

  app.component("MButton", MButton)
  app.component("MChip", MChip)
  app.component("MInput", MInput)
  app.component("MTabs", MTabs)

  app.use(router)
  router.isReady().then(() => {
    app.mount('#app')
  })
})()

